<template>
  <div class="w-screen -mt-16">
    <store-header :company="company" />
    <div class="container grid grid-cols-12 gap-8 pb-5">
      <div class="col-start-4 col-span-9">
        <h1 class="font-bold text-2xl capitalize">
          {{ company.name }}
        </h1>
      </div>
      <div class="col-span-3 h-auto shadow p-5">
        <h1 class="text-lg font-bold">Presentazione</h1>
        <p>{{ presentation_details.attribute_value }}</p>
        <h1 class="mt-4 text-lg font-bold">Altro</h1>
        <p>{{ other_details.attribute_value }}</p>
      </div>
      <h1 v-if="products.length === 0">No products</h1>

      <div
        class="col-span-9 grid grid-cols-1 gap-2 max-h-screen overflow-y-scroll"
        v-else
      >
        <single-product
          v-for="(product, p) in products"
          :key="p"
          :product="product"
        />
      </div>
      <!-- <div class="col-span-3">
        <div class="mb-4">
          <router-link
            :to="`/${$route.params.lang}/store/admin/editstore`"
            class="flex items-center border-t border-gray-800 px-2 py-2 cursor-pointer hover:bg-gray-100"
            active-class="active-sub"
          >
            <p class="flex-grow">
              Il mio negozio
            </p>
            <i class="mdi mdi-chevron-right text-2xl"></i>
          </router-link>
          <router-link
            :to="`/${$route.params.lang}/store/admin/addproduct`"
            class="flex items-center border-t border-gray-800 px-2 py-2 cursor-pointer hover:bg-gray-100"
            active-class="active-sub"
          >
            <p class="flex-grow" active-class="active-sub">
              Aggiungi prodotti
            </p>
            <i class="mdi mdi-chevron-right text-2xl"></i>
          </router-link>
          <router-link
            :to="`/${$route.params.lang}/store/admin/editproducts`"
            class="flex items-center border-t border-gray-800 px-2 py-2 cursor-pointer hover:bg-gray-100"
            active-class="active-sub"
          >
            <p class="flex-grow" active-class="active-sub">
              Visualizza prodotti
            </p>
            <i class="mdi mdi-chevron-right text-2xl"></i>
          </router-link>
          <router-link
            :to="`/${$route.params.lang}/store/admin/orders`"
            class="flex items-center border-t border-gray-800 px-2 py-2 cursor-pointer hover:bg-gray-100"
            active-class="active-sub"
          >
            <p class="flex-grow">
              Gestione ordini
            </p>
            <i class="mdi mdi-chevron-right text-2xl"></i>
          </router-link>
        </div>
      </div> -->
      <!-- <div class="row-start-3 col-span-3"> -->
      <!-- <router-view></router-view> -->

      <!-- </div> -->
      <!-- <pre>
      {{ currentUserData }}
    </pre
      > -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StoreHeader from "./_components/StoreHeader.vue";
import { API_URL } from "@/app-axios.js";
import SingleProduct from "./_components/SingleProduct";
// import { Auth } from "aws-amplify";

export default {
  components: { StoreHeader, SingleProduct },
  data() {
    return {
      company: null,
      products: []
    };
  },
  async beforeCreate() {
    try {
      let products = await API_URL({
        url: "/products",
        params: {
          company: this.$route.params.marchantslug,
          published: true
        },
        headers: {
          "aws-access-token": this.token
        }
      });
      this.products = products.data.content;
    } catch (error) {
      console.log(error);
    }
  },
  async beforeMount() {
    let n = await API_URL({
      url: `/companies/ids/${this.$route.params.marchantslug}`
    });
    console.log("ID ", n.data);
    this.company = n.data;

    // console.log(products);
    // if (!this.companyId) {
    //   await this.$store.dispatch("auth/getCurrentUser");
    //   console.log("Company Id non esiste. lo cerco", this.currentUserData);
    //   this.$store.commit(
    //     "products/SET_COMPANY_ID",
    //     this.$store.getters["auth/companyId"]
    //   );
    // }
    // console.log(
    //   "%cCarico le informazioni sullo store",
    //   "color:teal; font-size:1.3rem",
    //   this.companyId
    // );
    // let s = await API_URL({ url: `/companies/ids/${this.companyId}` });
    // this.$store.commit("createstore/SET_COMPANY", s.data);
    // console.log("API URL CALL ", s);
    //await this.loadStore();
  },
  async mounted() {},

  computed: {
    ...mapGetters("auth", ["currentUserData"]),
    ...mapGetters("products", ["companyId"]),
    ...mapGetters("auth", ["token"]),

    presentation_details: {
      get() {
        if (!this.company.company_details)
          return { attribute_value: "No details" };
        let pt =
          this.company.company_details.filter(
            cd => cd.attribute_name === "presentazione"
          )[0] || "";
        return pt;
      }
    },
    other_details: {
      get() {
        if (!this.company.company_details)
          return { attribute_value: "No details" };

        let pt =
          this.company.company_details.filter(
            cd => cd.attribute_name === "altro"
          )[0] || "";

        return pt;
      }
    }

    //...mapState("createstore", ["company"])
  },
  // watch: {
  //   companyId(n, o) {
  //     console.log("WATCH ", o, n, this.$store);
  //     this.$store.commit("products/SET_COMPANY_ID", n);
  //   }
  // },
  methods: {
    ...mapActions("createstore", ["loadStore"])
  }
};
</script>

<style lang="postcss">
.course + .course {
  border-top: 1px solid black;
}
</style>
