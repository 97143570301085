<template>
  <div class="flex flex-col p-5 pb-3 course">
    <div class="flex justify-between">
      <div class="flex w-10/12 gap-5">
        <img
          :src="product.product_photo"
          alt=""
          class="w-1/6 md:w-1/4 h-auto object-cover object-center"
        />
        <p class="font-bold">
          {{ product.product_name }} <br />
          <span class="font-light text-xs">{{ formatedDate }}</span>
        </p>
      </div>
      <span class="font-bold">{{ product.discounted_price | price }}</span>
    </div>
    <div class="flex justify-between items-center">
      <button @click="toggleShowMore">
        <span class="font-bold flex items-center gap-2"
          >Maggiori dettagli sul corso
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="12px"
            height="12px"
            viewBox="0 0 451.847 451.847"
            style="enable-background: new 0 0 451.847 451.847"
            xml:space="preserve"
          >
            <g>
              <path
                d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
              />
            </g>
          </svg>
        </span>
      </button>
      <button
        class="btn btn-cta flex items-center gap-3"
        @click="addItem(product)"
      >
        <span class="font-bold">Aggiungi al carrello</span
        ><i class="mdi mdi-cart-plus text-xl text-current"></i>
      </button>
    </div>
    <div
      class="overflow-hidden pt-4"
      :class="!showMoreVisible ? 'max-h-0' : 'show'"
      v-html="product.product_description"
    ></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SingleProduct",
  data() {
    return {
      showMoreVisible: false
    };
  },
  props: {
    product: {}
  },
  methods: {
    ...mapActions("cart", ["addItem"]),

    toggleShowMore() {
      this.showMoreVisible = !this.showMoreVisible;
    }
  },
  computed: {
    formatedDate() {
      let formatedDate = this.product.service_start_date;
      return formatedDate.substring(0, 10);
    }
  }
};
</script>

<style lang="postcss" scoped>
.max-h-0 {
  max-height: 0;
  transition: max-height ease-out 0.3s;
}

.show {
  transition: max-height ease-in 0.5s;
  max-height: 500px;
}
</style>
